<div id="email-verification-form">
      <form>
        <div class="modal-body">
          <div class="row">
            <div class="col-md-8 open-new-request-title">
              <span> {{'emailVerification.title' | translate}}</span>
            </div>
            <div class="col-md-4">
              <mat-form-field class="form-field-wrapper form-field" appearance="outline" style="width: 100% !important;">
                <mat-label>Language</mat-label>
                <mat-select [(ngModel)]="selectedLanguage" [compareWith]="compareLanguages" (selectionChange)="onLanguageSelected($event)" name="language">
                  <mat-option class="app-mat-option" *ngFor="let lan of languages" [value]="lan">
                    {{ lan.name }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>
        </div>
      </form>
<!--    </div>-->

<!--  </div>-->
  <form #form="ngForm">
    <div class="modal-body">
      <div>
        <div>
          <div class="row form-field">
            {{message}}
          </div>
          <div class="row">
            <div class="col-md-8">
              <mat-form-field class="form-field-wrapper form-field" style="width: 100% !important;" appearance="outline">
                <mat-label>{{'form.email' | translate}}</mat-label>
                <input [disabled]="codeSent" matInput [(ngModel)]="emailAddress" name="email" #email="ngModel"
                       required minlength="6" maxlength="64">
<!--                <mat-error *ngIf="email.touched && email.invalid">{{'emailVerification.invalidEmail' | translate}}</mat-error>-->
                <mat-error *ngIf="email.touched && email.invalid && email.errors?.['minlength']">
                  {{'emailVerification.minCharsRequired' | translate: {count: 6} }}
                </mat-error>
              </mat-form-field>
            </div>
            <div *ngIf="codeSent" class="col-md-4">
              <mat-form-field class="form-field-wrapper form-field" style="width: 100% !important;" appearance="outline">
                <mat-label>{{'emailVerification.code' | translate}}</mat-label>
                <input matInput [(ngModel)]="code" name="verificationCode" #verificationCode="ngModel"
                       required minlength="6" maxlength="6">
<!--                <mat-error *ngIf="verificationCode.touched && verificationCode.invalid">Invalid Code</mat-error>?-->
                <mat-error *ngIf="verificationCode.touched && verificationCode.invalid && verificationCode.errors?.['minlength']">
                  {{'emailVerification.minCharsRequired' | translate: {count: 6} }}
                </mat-error>
              </mat-form-field>
            </div>
          </div>
          <div class="row">
            <div *ngIf="showContinue" class="col-md-4">
              <button class="btn btn-primary submit-btn form-field"
                      [disabled]="form.invalid || disableContinue" (click)="onContinue()">
                {{'emailVerification.continue' | translate}}
              </button>
            </div>
            <div *ngIf="verificationError" class="col-md-8" style="color: #d9534f">
              {{verificationError}}
            </div>
          </div>
        </div>
        <div class="row">
          <div *ngIf="codeSent" class="col-md-4">
            <button class="btn btn-primary submit-btn"
                    [disabled]="form.invalid || disableSubmit" (click)="onSubmit()">
              {{'emailVerification.submit' | translate}}
            </button>
          </div>
          <div *ngIf="validationError" class="col-md-8" style="color: #d9534f">
            {{validationError}}
          </div>
        </div>
      </div>
    </div>
  </form>
</div>
