import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {environment} from "../../environments/environment";
import {DsarRequest} from "../models/dsar-request.model";
import {Observable, tap} from "rxjs";
import {Language} from "../models/language.model";

@Injectable()
export class DsarRequestService {

  constructor(private http: HttpClient){}

  languages: Language[] = [];
  getFormData(language: string): Observable<any> {
    return this.http.get(environment.API_BASE_URL + '/form-data/' + language);
  }

  createDsarRequest(request: DsarRequest): Observable<any> {
    return this.http.post(environment.API_BASE_URL, request, {
      headers: {'Content-Type': 'application/json'}
    });
  }

  sendEmailVerificationCode(email: string): Observable<any> {
    return this.http.put(environment.API_BASE_URL + "/otp/" + email, null, {
      headers: {
        'Content-Type': 'application/json'
      }
    });
  }

  checkCodeValidity(email: string, code: string): Observable<any> {
    return this.http.put(environment.API_BASE_URL + "/otp/" + email + "/" + code, null, {
      headers: {
        'Content-Type': 'application/json'
      }
    });
  }

  loadLanguages(): Observable<any> {
    return this.http.get(environment.API_BASE_URL + '/translations/languages', {
      headers: {
        'Content-Type': 'application/json'
      }
    }).pipe(
      tap(({
          next: (data: any) => {
            const languages = data.map((lang: any) => {
              return {
                code: lang.code,
                name: lang.value
              };
            });
            languages.sort((a: Language, b: Language) => {
              return a.name > b.name ? 1 : -1;
            });
            this.setLanguages(languages);
          }, error: (error) => {
            console.log(error);
            // todo handle error, set languages to English
          }
        })
      )
    );
  }

  getLanguages(): Language[] {
    return this.languages;
  }

  setLanguages(languages: Language[]): void {
    this.languages = languages;
  }

}
