import {Injectable} from "@angular/core";
import {Observable, tap} from "rxjs";
import {environment} from "../../environments/environment";
import {HttpClient} from "@angular/common/http";

@Injectable()
export class AuthService {

  constructor(private http: HttpClient) {
  }

  token = '';

  getToken(): string {
    return this.token;
  }

  setToken(token: string) {
    this.token = token;
  }

}
