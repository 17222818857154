import {TranslateLoader} from "@ngx-translate/core";
import {Observable} from "rxjs";
import {HttpClient} from "@angular/common/http";
import {environment} from "../../environments/environment";

export class TranslateLoaderService implements TranslateLoader {

  constructor(private http: HttpClient) {}
  getTranslation(lang: string): Observable<object> {
    return this.http.get(environment.API_BASE_URL + "/translations/" + lang);
  }

}
