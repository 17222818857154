import {Component, OnInit} from '@angular/core';
import {DsarRequestService} from "../services/dsar-request.service";

@Component({
  selector: 'app-health-check',
  templateUrl: './health-check.component.html',
  styleUrls: ['./health-check.component.css']
})
export class HealthCheckComponent implements OnInit {

  healthy = '';
  ngOnInit(): void {
    this.healthy = 'true';
  }

}
