import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {DsarFormComponent} from "./dsar-form/dsar-form.component";
import {PageNotFoundComponent} from "./page-not-found/page-not-found.component";
import {HealthCheckComponent} from "./health-check/health-check.component";
import {EmeaNoticeComponent} from "./cookie-notice/emea-notice.component";
import {CookieNoticeComponent} from "./cookie-notice/cookie-notice.component";

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'irrs-gpo',
  },
  { path: 'health', component: HealthCheckComponent },
  { path: 'irrs-gpo', component: DsarFormComponent },
  { path: 'cookie-notice', component: CookieNoticeComponent },
  { path: 'cookie-notice/emea', component: EmeaNoticeComponent },
  { path: '**', component: PageNotFoundComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
