<!DOCTYPE HTML>
<html lang="en">
<head>
  <meta charset="UTF-8"/>
  <title>EU Cookie Notice Template</title>
  <!--*********************************************************************************************************************************************************************************** -->
  <!--REPLACE this section OneTrust Specific Domain Cookie consent Script -->
  <!--*********************************************************************************************************************************************************************************** -->
  <!-- OneTrust Cookies Consent Notice start for privacy.bnymellon.com -->
  <script type="text/javascript" src="https://cdn.cookielaw.org/consent/385835d2-f701-4e3e-a612-12638f48366f/OtAutoBlock.js" ></script>
  <script src="https://cdn.cookielaw.org/scripttemplates/otSDKStub.js"  type="text/javascript" charset="UTF-8" data-domain-script="385835d2-f701-4e3e-a612-12638f48366f" ></script>
  <script type="text/javascript">
    function OptanonWrapper() { }
  </script>
</head>
<body class="page basicpage" style="overflow: scroll">


<!--*********************************************************************************************************************************************************************************** -->
<!--EU Cookie NOTICE Section-->
<!--VERSION 1.1  14 March 2023  DB-->
<!--*********************************************************************************************************************************************************************************** -->
<div class="container responsivegrid site-max-width content-position-bottom aem-GridColumn aem-GridColumn--default--12" style="margin-top: 30px !important;">
  <div class="cmp-container container-x-small container-mobile-x-small " style="background-color:#E3EBED;" data-aos-distance="10">
    <div>
      <div class="aem-Grid aem-Grid--12 aem-Grid--default--12 ">
        <div class="container responsivegrid content-max-width aem-GridColumn aem-GridColumn--default--12">
          <div class="cmp-container   " data-aos-distance="10">
            <div>
              <div class="aem-Grid aem-Grid--12 aem-Grid--default--12 ">
                <div class="breadcrumb cmp-breadcrumb-arrow-before aem-GridColumn aem-GridColumn--default--12">
                  <nav class="cmp-breadcrumb" aria-label="Breadcrumb">
                    <ol class="cmp-breadcrumb__list" itemscope="" itemtype="http://schema.org/BreadcrumbList">
                      <li class="cmp-breadcrumb__item" itemprop="itemListElement" itemscope="" itemtype="http://schema.org/ListItem">
                        <a href="https://www.bnymellon.com/emea/en.html" class="cmp-breadcrumb__item-link" itemprop="item">
                          <span itemprop="name">BNY Mellon | EMEA Region</span>
                        </a>
                        <meta itemprop="position" content="1">
                      </li>
                    </ol>
                  </nav>
                </div>
                <div class="text padding-bottom-lg aem-GridColumn aem-GridColumn--default--12">
                  <div class="cmp-text " data-aos-distance="10">
                    <h1 role="heading" aria-level="2" data-uw-rm-heading="level">Cookie Notice</h1>
                  </div>
                </div>
              </div>
            </div>
            <!-- if the background images are not uploaed, the markup doesnt show up-->
          </div>
        </div>
      </div>
    </div>
    <!-- if the background images are not uploaed, the markup doesnt show up-->
  </div>
</div>
<div class="root responsivegrid">
  <div class="aem-Grid aem-Grid--12 aem-Grid--default--12">
    <div class="container responsivegrid content-max-width padding-top-xl padding-bottom-xl aem-GridColumn--default--none aem-GridColumn aem-GridColumn--default--10 aem-GridColumn--offset--default--0">
      <div class="cmp-container container-medium" data-aos-distance="10">
        <div>
          <div class="aem-Grid aem-Grid--10 aem-Grid--default--10">
            <div class="bnymEmbed embed aem-GridColumn--default--none aem-GridColumn aem-GridColumn--offset--default--2
           aem-GridColumn--default--6">
              <div class="cmp-embed">
                <div id="otnotice-27b7a8a1-476b-466d-ae6e-fdb89c6131d4"> </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
</body>
</html>
