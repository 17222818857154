<div class="container responsivegrid site-max-width content-position-bottom" style="margin-top: 30px !important;">
  <hr style="margin-bottom: 0">
  <div class="cmp-container container-x-small container-mobile-x-small " style="background-color:#E3EBED;" data-aos-distance="10">
    <div>
      <div class="aem-Grid aem-Grid--12 aem-Grid--default--12 ">
        <div class="container responsivegrid content-max-width content-position-bottom aem-GridColumn aem-GridColumn--default--12">
          <div class="cmp-container  container-mobile-small " data-aos-distance="10">
            <div>
              <div class="aem-Grid aem-Grid--12 aem-Grid--default--12 aem-Grid--phone--12 ">
                <div class="text padding-bottom-lg aem-GridColumn--default--none aem-GridColumn--phone--none aem-GridColumn--phone--12 aem-GridColumn aem-GridColumn--default--12 aem-GridColumn--offset--phone--0 aem-GridColumn--offset--default--0">
                  <div class="cmp-text " data-aos-distance="10">
                    <h1 role="heading" aria-level="2" data-uw-rm-heading="level">Contact Us</h1>
                  </div>
                </div>
              </div>
            </div>
            <!-- if the background images are not uploaed, the markup doesnt show up-->
          </div>
        </div>
      </div>
    </div>
    <!-- if the background images are not uploaed, the markup doesnt show up-->
  </div>
</div>
<!--<div class="text aem-GridColumn&#45;&#45;default&#45;&#45;none aem-GridColumn&#45;&#45;phone&#45;&#45;none aem-GridColumn&#45;&#45;phone&#45;&#45;10 aem-GridColumn aem-GridColumn&#45;&#45;offset&#45;&#45;default&#45;&#45;2 aem-GridColumn&#45;&#45;default&#45;&#45;6 aem-GridColumn&#45;&#45;offset&#45;&#45;phone&#45;&#45;0">-->
<!--  -->
<!--</div>-->
<div *ngIf="languageLoaded" id="new-request-form">
  <div class="row modal-header">
    <div class="col-md-10 modal-header">
      <div class="cmp-text open-new-request-title" data-aos-distance="10">
        <h2>{{'form.title' | translate}}</h2>
      </div>
<!--      <span class="open-new-request-title"> {{'form.title' | translate}}</span>-->
    </div>
    <div *ngIf="emailDialogClosed" class="col-md-2">
      <form>
        <div class="modal-body">
          <mat-form-field class="form-field privacy-new-form-request-form-field" appearance="outline">
            <mat-label>Language</mat-label>
            <mat-select *ngIf="languages" [(ngModel)]="selectedLanguage" [compareWith]="compareLanguages" (selectionChange)="onLanguageSelected($event)" name="language">
              <mat-option class="app-mat-option" *ngFor="let lan of languages" [value]="lan">
                {{ lan.name }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </form>
    </div>
  </div>
  <form #form="ngForm">
    <div class="modal-body">
      <div>
        <div>
          <div class="row">
            <div class={{rowClass}}>
              <mat-form-field class="form-field privacy-new-form-request-form-field" appearance="outline">
                <mat-label>{{'form.country' | translate}}</mat-label>
                <mat-select (selectionChange)="onCountrySelected($event)" name="country" ngModel required>
                  <mat-option class="app-mat-option" *ngFor="let country of countries" [value]="country"
                              [disabled]="country.name==='Loading...'">
                    {{ country.name }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="col-md-3" *ngIf="this.states.length > 0">
              <mat-form-field class="form-field privacy-new-form-request-form-field" appearance="outline">
                <mat-label>{{'form.state' | translate}}</mat-label>
                <mat-select (selectionChange)="onStateSelected($event)" name="state" ngModel required
                            [disabled]="this.states.length <= 0">
                  <mat-option class="app-mat-option" *ngFor="let state of states" [value]="state">
                    {{ state.name }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class={{rowClass}}>
              <mat-form-field class="form-field privacy-new-form-request-form-field" appearance="outline">
                <mat-label>{{'form.subjectType' | translate}}</mat-label>
                <mat-select (selectionChange)="onRelationshipSelected($event)" name="relationship" ngModel required>
                  <mat-option class="app-mat-option" *ngFor="let relationship of dataSubjectTypes" [value]="relationship">
                    {{ relationship.value }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class={{rowClass}}>
              <mat-form-field class="form-field privacy-new-form-request-form-field" appearance="outline">
                <mat-label>{{'form.requestType' | translate}}</mat-label>
                <mat-select (selectionChange)="onRequestTypeSelected($event)" name="requestType" ngModel required multiple>
                  <mat-option class="app-mat-option" *ngFor="let requestType of requestTypes" [value]="requestType.name"
                  [disabled]="requestType.name==='Please select country first.' ||
                   requestType.name==='No Request Types Found for the Country Selected'">
                    {{requestType.name}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <mat-form-field class="form-field privacy-new-form-request-form-field" appearance="outline">
                <mat-label>{{'form.details' | translate}}	</mat-label>
                <textarea matInput [(ngModel)]="newRequest.description" name="description"
                          required minlength="3" maxlength="1000" cdkTextareaAutosize cdkAutosizeMinRows="3"
                          cdkAutosizeMaxRows="10" (change)="onInputChange()"> </textarea>
              </mat-form-field>
            </div>
          </div>
          <div class="row">
            <div class="col-md-4">
              <mat-form-field class="form-field privacy-new-form-request-form-field" appearance="outline">
                <mat-label>{{'form.firstName' | translate}}</mat-label>
                <input matInput [(ngModel)]="newRequest.firstName" name="firstName" #firstName="ngModel"
                       required="true" [pattern]="regexes.firstAndLastNameRegx" minlength="2" maxlength="30"
                       (change)="onInputChange()">
                <mat-error *ngIf="firstName.touched && firstName.invalid && firstName.errors?.['pattern']">
                  {{'form.onlyLettersAllowed' | translate}}
                </mat-error>
                <mat-error *ngIf="firstName.touched && firstName.invalid && firstName.errors?.['required']">
                  {{'form.mandatoryField' | translate}}
                </mat-error>
                <mat-error *ngIf="firstName.touched && firstName.invalid && firstName.errors?.['minlength']">
                  {{'form.minCharsRequired' | translate: {count: 2} }}
                </mat-error>
              </mat-form-field>
            </div>
            <div class="col-md-4">
              <mat-form-field class="form-field privacy-new-form-request-form-field" appearance="outline">
                <mat-label>{{'form.lastName' | translate}}</mat-label>
                <input matInput [(ngModel)]="newRequest.lastName" name="lastName" #lastName="ngModel"
                       required [pattern]="regexes.firstAndLastNameRegx" minlength="2" maxlength="30"
                       (change)="onInputChange()">
                <mat-error *ngIf="lastName.touched && lastName.invalid && lastName.errors?.['pattern']">
                  {{'form.onlyLettersAllowed' | translate}}
                </mat-error>
                <mat-error *ngIf="lastName.touched && lastName.invalid && lastName.errors?.['required']">
                  {{'form.mandatoryField' | translate}}
                </mat-error>
                <mat-error *ngIf="lastName.touched && lastName.invalid && lastName.errors?.['minlength']">
                  {{'form.minCharsRequired' | translate: {count: 2} }}
                </mat-error>
              </mat-form-field>
            </div>
            <div class="col-md-4">
              <mat-form-field class="form-field privacy-new-form-request-form-field" appearance="outline">
                <mat-label>{{'form.email' | translate}}</mat-label>
                <input [disabled]="true" matInput [(ngModel)]="newRequest.email" name="email" #email="ngModel"
                       required email minlength="6" maxlength="64" (change)="onInputChange()">
                <mat-error *ngIf="email.touched && email.invalid">Invalid email</mat-error>
                <mat-error *ngIf="email.touched && email.invalid && email.errors?.['minlength']">
                  {{'form.minCharsRequired' | translate: {count: 6} }}
                </mat-error>
              </mat-form-field>
            </div>
          </div>
          <div class="row">
            <div class="col-md-10">
              <mat-checkbox
                [(ngModel)]="certified"
                [disabled]="!(newRequest.firstName && newRequest.lastName)" name="certification">
                {{'form.certification' | translate}}
                <span style="font-weight: bold">
                  {{(newRequest.firstName && newRequest.lastName)? newRequest.firstName + ' ' + newRequest.lastName:''}}
                </span>
              </mat-checkbox>
            </div>
            <div class="col-md-2">
              <button class="btn btn-primary"
                      [disabled]="form.invalid || !certified || disableSubmit" (click)="onSubmit()">
                {{'form.submit' | translate}}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
  <div class="modal-body">

  </div>
</div>
