import {Component, OnInit} from '@angular/core';
import { TranslateService } from "@ngx-translate/core";
import {DsarRequestService} from "./services/dsar-request.service";
import {environment} from "../environments/environment";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  element: HTMLElement | null = null;
  token = undefined
  languages = undefined;
  constructor(private translate: TranslateService, private dsarService: DsarRequestService){
    translate.setDefaultLang('en');
    translate.use('en');
    dsarService.loadLanguages().subscribe({
      next: (data) => {
        this.languages = data;
      }
    });
  }
  ngOnInit(): void {
    this.addScriptsToHead();
  }

  addScriptsToHead() {
    const head = document.getElementsByTagName('head')[0];
    const script1 = document.createElement('script');
    const script2 = document.createElement('script');
    script2.setAttribute('src', 'https://cdn.cookielaw.org/scripttemplates/otSDKStub.js');
    if(this.isProduction()) {
      script1.setAttribute('src', 'https://cdn.cookielaw.org/consent/385835d2-f701-4e3e-a612-12638f48366f/OtAutoBlock.js');
      script2.setAttribute("data-domain-script", "385835d2-f701-4e3e-a612-12638f48366f");
    } else {
      script1.setAttribute('src','https://cdn.cookielaw.org/consent/385835d2-f701-4e3e-a612-12638f48366f-test/OtAutoBlock.js');
      script2.setAttribute("data-domain-script", "385835d2-f701-4e3e-a612-12638f48366f-test");
    }
    script1.setAttribute('type', 'text/javascript');
    script1.setAttribute('charset', 'UTF-8');
    script2.setAttribute('type', 'text/javascript');
    script2.setAttribute('charset', 'UTF-8');
    head.insertBefore(script2, head.firstChild);
    head.insertBefore(script1, head.firstChild);
  }

  isProduction() {
    return environment.production;
  }

}
