<div *ngIf="languages" class="container responsivegrid site-max-width content-position-bottom aem-GridColumn aem-GridColumn--default--12">
  <div class="row" >
    <img id="header-image" src="assets/bny_logo_color.svg" alt=""/>
  </div>
  <router-outlet></router-outlet>
  <div class="footer">
    <hr>
    <div class="row">
      <div class="col-4">
        <a href="https://www.bnymellon.com/us/en/data-privacy.html" target="_blank" rel="noopener noreferrer">
          <span class="">{{'form.footer.privacy' | translate}}</span>
        </a>
      </div>
      <div class="col-4">
        <a href="/cookie-notice">
          <span class="">{{'form.footer.cookieNotice' | translate}}</span>
        </a>
      </div>
      <div class="col-4">
        <a id="ot-sdk-btn" class="ot-sdk-show-settings">
          {{'form.footer.manageCookies' | translate}}
        </a>
      </div>
      <div>
        <p></p>
        <p>©2024 THE BANK OF NEW YORK MELLON CORPORATION</p>
      </div>
    </div>
  </div>
</div>

