<!DOCTYPE html>
<html lang="en">
<head>
  <meta charset="UTF-8">
  <title>Title</title>
</head>
<body>
<main class="container responsivegrid aem-GridColumn aem-GridColumn--default--12"><h1 role="heading" aria-level="1" data-uw-rm-heading="h1" style="clip: rect(1px, 1px, 1px, 1px)!important;height:1px!important;width:1px!important;overflow:hidden!important;position:absolute!important;top:0!important;left:0!important;z-index:-1!important;opacity:0!important" id="userway-h1-heading" data-uw-rm-ignore="">Cookie Notice | BNY Mellon</h1>
  <div class="cmp-container    " data-aos-distance="10">
    <div>
      <div class="container responsivegrid site-max-width content-position-bottom" style="margin-top: 30px !important;">
        <div class="cmp-container container-x-small container-mobile-x-small " style="background-color:#E3EBED;" data-aos-distance="10">
          <div>
            <div class="aem-Grid aem-Grid--12 aem-Grid--default--12 ">
              <div class="container responsivegrid content-max-width content-position-bottom aem-GridColumn aem-GridColumn--default--12">
                <div class="cmp-container  container-mobile-small " data-aos-distance="10">
                  <div>
                    <div class="aem-Grid aem-Grid--12 aem-Grid--default--12 aem-Grid--phone--12 ">
                      <div class="breadcrumb cmp-breadcrumb-arrow-before aem-GridColumn aem-GridColumn--default--12">
                        <nav class="cmp-breadcrumb" aria-label="Breadcrumb">
                          <ol class="cmp-breadcrumb__list" itemscope="" itemtype="http://schema.org/BreadcrumbList">
                            <li class="cmp-breadcrumb__item" itemprop="itemListElement" itemscope="" itemtype="http://schema.org/ListItem">
                              <a href="https://www.bnymellon.com/us/en.html" class="cmp-breadcrumb__item-link" itemprop="item">
                                <span itemprop="name">BNY Mellon</span>
                              </a>
                              <meta itemprop="position" content="1">
                            </li>
                          </ol>
                        </nav>
                      </div>
                      <div class="text padding-bottom-lg aem-GridColumn--default--none aem-GridColumn--phone--none aem-GridColumn--phone--12 aem-GridColumn aem-GridColumn--default--12 aem-GridColumn--offset--phone--0 aem-GridColumn--offset--default--0">
                        <div class="cmp-text " data-aos-distance="10">
                          <h1 role="heading" aria-level="2" data-uw-rm-heading="level">Cookie Notice</h1>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- if the background images are not uploaed, the markup doesnt show up-->
                </div>
              </div>
            </div>
          </div>
          <!-- if the background images are not uploaed, the markup doesnt show up-->
        </div>
      </div>
      <div class="container responsivegrid content-max-width padding-xl-y">
        <div class="cmp-container   " data-aos-distance="10">
          <div>
            <div class="aem-Grid aem-Grid--10 aem-Grid--default--10 aem-Grid--phone--10 ">
              <div class="text padding-top-sm padding-bottom-sm aem-GridColumn--default--none aem-GridColumn--phone--none aem-GridColumn--phone--10 aem-GridColumn aem-GridColumn--offset--default--2 aem-GridColumn--default--6 aem-GridColumn--offset--phone--0">
                <div class="cmp-text " data-aos-distance="10">
                  <h3>EMEA Region</h3>
                  <ul>
                    <li><a href="cookie-notice/emea">EN English Cookie Notice</a></li>
                  </ul>
                  <h3>All Other Regions<br role="presentation" data-uw-rm-sr="">
                  </h3>
                  <ul>
                    <li><a href="https://www.bnymellon.com/us/en/data-privacy.html">EN English</a>&nbsp;- non EMEA Cookie Notice</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <!-- if the background images are not uploaed, the markup doesnt show up-->
        </div>
      </div>
    </div>
    <!-- if the background images are not uploaed, the markup doesnt show up-->
  </div>
</main>
</body>
</html>
