import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {DsarRequestService} from "../services/dsar-request.service";
import {MatSelectChange} from "@angular/material/select";
import {TranslateService} from "@ngx-translate/core";
import {EmailForm} from "../models/email-form.model";
import {Language} from "../models/language.model";
import {AuthService} from "../services/auth.service";

@Component({
  selector: 'app-email-verification',
  templateUrl: './email-verification.component.html',
  styleUrls: ['./email-verification.component.css']
})
export class EmailVerificationComponent implements OnInit {

  disableSubmit = false;
  emailAddress = '';
  code: string | undefined;
  messageId = 1;
  message = "Please enter your email below and click continue. We will send a verification code to the entered email.";
  codeSent = false;
  showContinue = true;
  disableContinue = false;
  verificationError: string | undefined;
  validationError: string | undefined;
  languages: Language[];
  selectedLanguage: Language;
  outputData: EmailForm = {};

  constructor(private dialogRef: MatDialogRef<EmailVerificationComponent, EmailForm>,
              @Inject(MAT_DIALOG_DATA) public data: { languages: Language[] }, private dsarService: DsarRequestService,
              private translate: TranslateService, private authService: AuthService) {
    this.languages = this.data.languages;
    this.selectedLanguage = {
      code: 'en',
      name: 'English'
    };
    translate.setDefaultLang('en');
    translate.use('en');
  }

  ngOnInit() {
    this.translate.get('emailVerification.message').subscribe(message => {
      this.message = message;
    });
  }

  onSubmit() {
    this.showContinue = false;
    this.disableSubmit = true;
    if(this.emailAddress && this.code) {
      this.dsarService.checkCodeValidity(this.emailAddress, this.code).subscribe({
        next: (data) => {
          console.log("Code validity response" + data);
          this.authService.setToken(data.token);
          this.outputData = {
            email: this.emailAddress,
            selectedLanguage: this.selectedLanguage
          }
          this.dialogRef.close(this.outputData);
        }, error: (error) => {
          console.log("An error occurred: " + JSON.stringify(error));
          this.disableSubmit = false;
          this.validationError = "An error occurred. Please try again."
        }
      });
    }
  }

  onContinue() {
    this.verificationError = undefined;
    this.disableContinue = true;
    if(this.emailAddress) {
      this.dsarService.sendEmailVerificationCode(this.emailAddress).subscribe({
        next: () => {
          this.codeSent = true;
          this.showContinue = false;
          this.messageId = 2;
          this.setCodeMessage();
        }, error: (error) => {
          console.log("An error occurred: " + JSON.stringify(error));
          this.disableContinue = false;
          this.verificationError = "An error occurred.";
        }
      });
    }
  }

  onLanguageSelected($event: MatSelectChange) {
    this.selectedLanguage = $event.value;
    this.translate.use($event.value.code);
    if(this.messageId === 1){
      this.setMessage()
    }
    else if (this.messageId === 2) {
      this.setCodeMessage()
    }
  }

  compareLanguages(language1: Language, language2: Language): boolean {
    return !!language2 && language1.name === language2.name;
  }

  setMessage() {
    this.translate.get('emailVerification.message').subscribe(message => {
      this.message = message;
    });
  }

  setCodeMessage() {
    this.translate.get('emailVerification.codeMessage').subscribe(message => {
      this.message = message;
    });
  }

}
