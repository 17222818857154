
import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpInterceptor, HttpClient,
} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from "../../environments/environment";
import {AuthService} from "../services/auth.service";

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  constructor(private http: HttpClient, private authService: AuthService) {
  }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<any> {
    const token = this.authService.getToken();
    if (token && !this.excludedUrls(request.url)){
      request = request.clone({
        setHeaders: {
          "token": token,
          "Authorization": "Bearer " + token
        },
      });
    }
    return next.handle(request);
  }

  excludedUrls(url: string) {
    return url.includes(environment.OIDC_API_URL) || url.includes("translations") || url.includes("otp");
  }
}
