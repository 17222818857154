import {AfterViewChecked, Component, OnInit} from "@angular/core";

@Component({
  selector: 'app-emea-notice',
  templateUrl: './emea-notice.html',
  styleUrls: ['../styles/clientlib-base.min.css', './otnotice-core.css', '../styles/bnym-style.css', '../styles/staging-style.css']
})
export class EmeaNoticeComponent implements OnInit, AfterViewChecked {

  routerLink = 'cookie-notice/emea';
  ngOnInit(): void {
    oneTrustLoader.loadOneTrust();
  }

  ngAfterViewChecked() {
    const anchors = document.getElementsByTagName('a');
    for (let i = 0; i < anchors.length; i++) {
      if (anchors[i].hash) {
        const hash = anchors[i].hash.replace('#', '');
        anchors[i].setAttribute('href', `/${this.routerLink}#${hash}`);
        anchors[i].setAttribute('fragment', `${hash}`);
        anchors[i].setAttribute('ng-reflect-fragment', `${hash}`);
        anchors[i].setAttribute('routerlink', `/${this.routerLink}`);
        anchors[i].setAttribute('ng-reflect-router-link', `/${this.routerLink}`);
      }
    }
  }
}
