import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppComponent } from './app.component';
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import { MatSelectModule } from "@angular/material/select";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { DsarRequestService } from "./services/dsar-request.service";
import { HTTP_INTERCEPTORS } from "@angular/common/http";
import { TokenInterceptor } from "./interceptors/token-interceptor.service";
import { AuthModule } from "angular-auth-oidc-client";
import { DsarFormComponent } from './dsar-form/dsar-form.component';
import { AppRoutingModule } from "./app-routing.module";
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { MatSnackBarModule } from "@angular/material/snack-bar";
import { RecaptchaFormsModule, RecaptchaModule } from 'ng-recaptcha';
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { ProgressSpinnerComponent } from './progress-spinner/progress-spinner.component';
import { MatDialogModule } from "@angular/material/dialog";
import { HealthCheckComponent } from './health-check/health-check.component';
import { EmailVerificationComponent } from './email-verification/email-verification.component';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {HttpClient, HttpClientModule} from '@angular/common/http';
import {TranslateLoaderService} from "./services/translate-loader-service";
import {AuthService} from "./services/auth.service";
import {EmeaNoticeComponent} from "./cookie-notice/emea-notice.component";
import {CookieNoticeComponent} from "./cookie-notice/cookie-notice.component";

@NgModule({
  declarations: [
    AppComponent,
    DsarFormComponent,
    PageNotFoundComponent,
    ProgressSpinnerComponent,
    HealthCheckComponent,
    EmailVerificationComponent,
    CookieNoticeComponent,
    EmeaNoticeComponent
  ],
  imports: [
    BrowserModule,
    FormsModule,
    BrowserAnimationsModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatCheckboxModule,
    MatSnackBarModule,
    AppRoutingModule,
    RecaptchaModule,
    RecaptchaFormsModule,
    MatProgressSpinnerModule,
    MatDialogModule,
    BrowserModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient, DsarRequestService]
      }
    }),
    ReactiveFormsModule
  ],
  exports: [
    AuthModule
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true },
    DsarRequestService,
    AuthService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }

export function HttpLoaderFactory(http: HttpClient): TranslateLoaderService {
  return new TranslateLoaderService(http);
}
