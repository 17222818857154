import { Component, OnInit } from '@angular/core';
import { DsarRequest } from "../models/dsar-request.model";
import { MatSelectChange } from "@angular/material/select";
import { regexes } from "../constants/regexes";
import { MatSnackBar } from "@angular/material/snack-bar";
import { DsarRequestService } from '../services/dsar-request.service';
import { Country } from "../models/country.model";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import {ProgressSpinnerComponent} from "../progress-spinner/progress-spinner.component";
import {AccessRight} from "../models/access-right.model";
import {State} from "../models/state.model";
import {EmailVerificationComponent} from "../email-verification/email-verification.component";
import {KeyValuePair} from "../models/key-value-pair.model";
import {TranslateService} from "@ngx-translate/core";
import {Language} from "../models/language.model";
import {AuthService} from "../services/auth.service";

@Component({
  selector: 'app-dsar-form',
  templateUrl: './dsar-form.component.html',
  styleUrls: ['./dsar-form.component.scss', '../styles/clientlib-base.min.css']
})
export class DsarFormComponent implements OnInit {
  token: string | undefined;
  selectedCountry: Country | null = null;
  newRequest = {} as DsarRequest;
  regexes = regexes;
  data = [];
  rowClass = "col-md-4";
  countries: Country[] = [{
    id: 0,
    name: 'Loading...',
    areaCode: '0'
  }];
  states: State[] = [];
  dataSubjectTypes: KeyValuePair<number, string> [] = [];
  requestTypes: AccessRight [] = [{
    "id": 1,
    "name": "Loading...",
    "description": "",
    "createdDate": "",
    "updatedDate": "",
    "createdBy": "",
    "updatedBy": "",
    "active": false
  }];
  certified = false;
  disableSubmit = false;
  languages: Language[] = [];
  selectedLanguage: any = {};
  emailDialogClosed = false;
  languageLoaded = true;
  constructor(private snackBar: MatSnackBar, private dsarService: DsarRequestService,
              private dialog: MatDialog, private translate: TranslateService, private authService: AuthService) {
    translate.setDefaultLang('en');
    translate.use('en');
  }

  ngOnInit(): void {
    this.languages = this.dsarService.getLanguages();
    this.selectedLanguage = {
      code: 'en',
      name: 'English'
    };
    const emailDialog: MatDialogRef<EmailVerificationComponent> = this.openEmailVerificationDialog();
    emailDialog.afterClosed().subscribe(emailForm => {
      this.newRequest.email = emailForm.email;
      this.selectedLanguage = emailForm.selectedLanguage;
      this.emailDialogClosed = true;
      this.getFormData();
    });
    if(!this.selectedCountry) {
      this.requestTypes[0].name = "Please select country first."
    }
  }

  private getFormData() {
    const dialogRef = this.openDialog();
    this.dsarService.getFormData(this.selectedLanguage.code).subscribe({
      next: (data) => {
        dialogRef.close();
        this.countries = data.countries;
        this.countries.sort((a, b) => {
          return a.name > b.name ? 1 : -1;
        });
        this.dataSubjectTypes = data.dataSubjectTypes;
        this.dataSubjectTypes.sort((a, b) => {
          return a.value > b.value ? 1 : -1;
        });
        this.requestTypes = [{
          "id": 1,
          "name": "Please select country first.",
          "description": "",
          "createdDate": "",
          "updatedDate": "",
          "createdBy": "",
          "updatedBy": "",
          "active": false
        }];
      }, error: (error) => {
        dialogRef.close();
        console.log(error);
        // todo throw an error message on the screen and maybe consider refreshing
      }
    });
  }

  openEmailVerificationDialog(): MatDialogRef<EmailVerificationComponent> {
    return this.dialog.open(EmailVerificationComponent, {
      disableClose: true,
      width: '860px',
      data: {
        languages: this.languages
      }
    });
  }

  openDialog(): MatDialogRef<ProgressSpinnerComponent> {
    return this.dialog.open(ProgressSpinnerComponent, {
      disableClose: true,
      panelClass: 'transparent'
    });
  }
  onSubmit() {
    this.disableSubmit = true;
    const dialogRef = this.openDialog();
    this.dsarService.createDsarRequest(this.newRequest).subscribe({
      next: (data) => {
        dialogRef.close();
        this.snackBar.open('Form submitted! Request ID: ' + data.identity, '', {
          duration: 2000,
          verticalPosition: "top",
          panelClass: 'snack-bar-success'
        });
      }, error: (error) => {
        dialogRef.close();
        console.log("An error occurred: " + error);
        this.snackBar.open('Error occurred while submitting form', 'close', {
          duration: 5000,
          verticalPosition: "top",
          panelClass: 'snack-bar-success'
        });
        this.disableSubmit = false;
      }
    });
  }

  onCountrySelected($event: MatSelectChange) {
    this.newRequest.stateId = undefined;
    this.disableSubmit = false;
    this.selectedCountry = $event.value;
    if(this.selectedCountry) {
      this.newRequest.countryId = this.selectedCountry.id;
      if(this.selectedCountry.states) {
        this.states = this.selectedCountry.states;
        this.states.sort((a, b) => {
          return a.name > b.name ? 1 : -1;
        });
      }
      if(this.selectedCountry.rights && this.selectedCountry.rights?.length > 0) {
        this.requestTypes = this.selectedCountry.rights;
        this.requestTypes.sort((a, b) => {
          return a.name > b.name ? 1 : -1;
        });
      }
      else {
        this.requestTypes =[{
          "id": 1,
          "name": "No Request Types Found for the Country Selected",
          "description": "",
          "createdDate": "",
          "updatedDate": "",
          "createdBy": "",
          "updatedBy": "",
          "active": false
        }];
      }
    }
    if(this.states.length > 0) {
      this.rowClass = "col-md-3";
    }
    else {
      this.rowClass = "col-md-4";
    }
  }

  onRequestTypeSelected($event: MatSelectChange) {
    this.disableSubmit = false;
    this.newRequest.dsarRequestType = 1;
    this.newRequest.requestTypes = $event.value;
  }

  onRelationshipSelected($event: MatSelectChange) {
    this.disableSubmit = false;
    this.newRequest.dataSubjectType = $event.value.key;

  }

  onInputChange() {
    this.disableSubmit = false;
  }

  onLanguageSelected($event: MatSelectChange) {
    this.selectedLanguage = $event.value;
    this.translate.use($event.value.code);
    this.getFormData();
    this.selectedCountry = null;
  }

  compareLanguages(language1: Language, language2: Language): boolean {
    return !!language2 && language1.name === language2.name;
  }

  onStateSelected($event: MatSelectChange) {
    this.disableSubmit = false;
    this.newRequest.stateId = $event.value.id;
    this.requestTypes = $event.value.rights;
  }

}
