import {Component} from "@angular/core";

@Component({
  selector: 'app-cookie-notice',
  templateUrl: './cookie-notice.html',
  styleUrls: ['../styles/clientlib-base.min.css', './otnotice-core.css', '../styles/bnym-style.css', '../styles/staging-style.css']
})
export class CookieNoticeComponent {

}
